// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news-detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-onlinebar-detail-js": () => import("./../../../src/pages/onlinebar-detail.js" /* webpackChunkName: "component---src-pages-onlinebar-detail-js" */),
  "component---src-pages-onlinebar-js": () => import("./../../../src/pages/onlinebar.js" /* webpackChunkName: "component---src-pages-onlinebar-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-sightseeing-detail-js": () => import("./../../../src/pages/sightseeing-detail.js" /* webpackChunkName: "component---src-pages-sightseeing-detail-js" */),
  "component---src-pages-sightseeing-js": () => import("./../../../src/pages/sightseeing.js" /* webpackChunkName: "component---src-pages-sightseeing-js" */),
  "component---src-pages-toroku-js": () => import("./../../../src/pages/toroku.js" /* webpackChunkName: "component---src-pages-toroku-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-onlinebar-js": () => import("./../../../src/templates/onlinebar.js" /* webpackChunkName: "component---src-templates-onlinebar-js" */),
  "component---src-templates-sightseeing-js": () => import("./../../../src/templates/sightseeing.js" /* webpackChunkName: "component---src-templates-sightseeing-js" */)
}

